import { Component, OnInit, Output , EventEmitter, HostListener} from '@angular/core';
import { Category } from 'src/app/models/category';
import { ChangeFood, Restaurant, RestaurantItem} from 'src/app/models/restaurant'
import { Router } from '@angular/router';
import { AppProxy } from 'src/app/services/app-proxy';
import { OrdersService } from 'src/app/services/order-service';
import { MyOrder, Order } from 'src/app/models/order';
import { BussinssService } from 'src/app/services/bussinss-service';
import { DataService } from 'src/app/services/data.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogOutOfBoundsComponent } from 'src/app/components/dialog-out-of-bounds/dialog-out-of-bounds.component';

@Component({
  selector: 'app-your-order',
  templateUrl: './your-order.component.html',
  styleUrls: ['./your-order.component.css']
})
export class YourOrderComponent implements OnInit {
  @Output()changeShowPrice: EventEmitter<number> = new EventEmitter<number>();
  @Output()changByFood: EventEmitter<number> = new EventEmitter<number>();
  @Output()changePrice: EventEmitter<number> = new EventEmitter<number>();
  categores:Category[];
  categoresItem:RestaurantItem[];
  changeFood:ChangeFood[];
  totalPrice:number=0;
  allPrice=0;
  totalAmount:number=0;
  IsDelivery:number=0;
  isPickUp:number=0; 
  IsSetting:number=0;
  IsDeliverySelect:number=1;
  isPickUpSelect:number=0; 
  IsSettingSelect:number=0;
  checkBox=0;
  collectionFormCheckedOn='assets/images/Order_Food_Chack_Box_On.png';
  collectionFormCheckedOff='assets/images/Order_Food_Chack_Box_Off.png';
  order:MyOrder;
  orderList:Order[]=[];
  note:string;
  Name="";
  Image="";
  restaurant:Restaurant=new Restaurant;
  restaurantItem:RestaurantItem[]=[];
  priceDelivery:string="";
  showProgress=false;
  // @HostListener('window:popstate', ['$event'])
  // onPopState(event) {
  //   this.backPress();
  // }

  constructor(private router: Router,private dataService:DataService ,private proxy: AppProxy,private orderService:OrdersService,private bussinssService: BussinssService) {}

  editItem(order:Order,position :number)
  {
    let pos=0;
    {
      this.bussinssService.getRestaurantDetails(this.order.RestaurantID,false,false,false,null,null).subscribe((res:any)=>{
        this.restaurant=res;
        if(this.restaurant.LongImage!=null)
        this.Image=this.restaurant.LongImage;
        this.restaurantItem=this.restaurant.MenuList;
       for(let i=0;i<this.restaurantItem.length;i++)
       {
         if(this.restaurantItem[i].ID==order.MenuID)
         pos=Number(i);
     }
    this.orderService.restaurant=null;
    localStorage.MENU_ID=order.MenuID;
    localStorage.PRICE=this.totalPrice;
        localStorage.edit="true";
        localStorage.position=position;
        localStorage.pos=pos;
      this.router.navigate(['../toppings2']);
    });
  }
  }

  delete(order:Order)
  {
      let reset = confirm("האם אתה בטוח שברצונך למחוק את המנה ");
      if (reset)
      { 
        this.orderService.addToBagOrder(order.ID,this.order.OrderID,order.MenuNumber).subscribe((res:any)=>
    {
      this.orderService.getOrderDetails(false).subscribe((res:any)=>
      {
      this.order=res;
      if(this.IsDelivery==1)
      this.allPrice=Number(this.order.TotalPrice)+Number(this.order.DeliveryPrice);
      else
      this.allPrice=Number(this.order.TotalPrice);
      this.ngOnInit();
    });
          });
        }
    else{ 
    }
  }

  onFocusOutEvent(event: any){
    //console.log(event.target.value);
    localStorage.NOTE=this.note;
 }

  collectionFormClick(i)
  {
    this.startCollectionType();
if(i==0)
{
this.checkBox=1;
localStorage.DELIVERY=1;
this.priceDelivery=this.order.DeliveryPrice;
this.allPrice=Number(this.order.TotalPrice)+Number(this.order.DeliveryPrice);
}
else if(i==1)
  {
this.checkBox=2;
localStorage.DELIVERY=2;
this.priceDelivery="";
this.allPrice=Number(this.order.TotalPrice);
  }
else if(i==2)
{
this.checkBox=3;
localStorage.DELIVERY=3;
this.priceDelivery="";
this.allPrice=Number(this.order.TotalPrice);
}
  }

  startCollectionType()
  {
    this.checkBox=0;
  }

  limitLines(event: InputEvent, maxLines: number) {
    let text = (event.target as HTMLTextAreaElement).value;
    if (text.length > 0) {
      const lineCount = 1 + text.replace(/[^\n]/g, '').length;
      if (lineCount > maxLines) {
        const textArray = text.split('\n');
        const newText = textArray.reduce((result, line, lineNum, array) => {
          if (lineNum < maxLines) {
            return result.concat('\n').concat(line);
          }
          return result.concat(line);
        });
        (event.target as HTMLTextAreaElement).value = newText;
      }
    }
  }

  payment(){
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(localStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.router.navigate(['../mainHome']);
    if(this.checkBox==0)
    this.proxy.showError("עליך לבחור אחת \n מאפשרויות השירות: משלוח,\n  איסוף עצמי או ישיבה במקום","");
    else 
    {
    if(this.note=="" || this.note==undefined)
    this.note=null;
    let delivery=0;
    let pickUp=0;
    let sitting=0;
    if(this.checkBox==1)
    delivery=1;
    else if(this.checkBox==2)
    pickUp=1;
    else if(this.checkBox==3)
    sitting=1;
    if(this.order.OrderID>0)
    {
      if(delivery==1 && this.restaurant.MinimumOrder!='' && Number(this.order.TotalPrice) < Number(this.restaurant.MinimumOrder))
      {
        this.proxy.showError("לא הגעת למינימום הזמנה\nשל "+" ₪"+this.restaurant.MinimumOrder+"\n"+" (ללא דמי משלוח)","");
      }
      //else
      this.orderService.setUpdateOrder1(this.order.OrderID,Number(delivery),Number(pickUp),Number(sitting),1,this.note,1).subscribe((res:any)=>{
        
        this.router.navigate(["../securePayment"]);
        });
  }
    else
    this.proxy.showError("ההזמנה שלך ריקה","");
  }
  }

  // backPress()
  // {
  //   this.router.navigate(["../restaurant"]);
  //  // window.history.back();
  // }
  
  ngOnInit(): void {
    window.scroll(0,0);
    this.orderList=[];
    localStorage.DELIVERY=1;
    this.showProgress=true;
   this.orderService.getOrderDetails(false).subscribe((res:any)=>{
      this.order=this.orderService.order;
      this.showProgress=false;
      let clickToPay="לחץ לתשלום - "
      let priceTotal=clickToPay+" "+this.orderService.order.TotalPrice+" ₪";
       localStorage.PRICE_TOTAL=this.order.TotalPrice;
       localStorage.PRICE=this.order.TotalPrice;
       this.dataService.price.next(priceTotal);
       this.changePrice.emit();
       this.priceDelivery=this.order.DeliveryPrice;
      this.totalPrice=Number(this.order.TotalPrice);
      this.allPrice=Number(this.order.TotalPrice)+Number(this.order.DeliveryPrice);
      this.changePrice.emit();
      this.Name=this.order.RestaurantName;
     // this.Image=this.order.BigImage;
      if(this.order.Note!=null && this.order.Note!="null")
         this.note=this.order.Note;
    if(this.order!=null && this.order.list!=null && this.order.list.length>0)
    {
      for(let i=0;i<this.order.list.length;i++)
      {
        this.orderList.push(this.order.list[i]);
      }
    this.totalAmount=0;
  for( let j=0;j<this.orderList.length;j++)
  {   
{
this.totalAmount+=Number(this.orderList[j].Count);
//this.totalPrice=Number(this.order.TotalPrice);
}
  }
 
//this.bussinssService.getRestaurantDetails_(this.order.RestaurantID).subscribe((res:any)=>{
  this.restaurant=this.bussinssService.restaurant;
  if(this.restaurant.LongImage!=null)
  this.Image=this.restaurant.LongImage;
  {
    for(let i=0;i<this.restaurant.listSetting.length;i++)
  {
    if(this.restaurant.listSetting[i].Text=="משלוח")
    {
      if(this.restaurant.listSetting[i].Show==1)
      {
      localStorage.DELIVERY=1;
      this.checkBox=1;
      this.IsDelivery=1;
      }
    }
    if(this.restaurant.listSetting[i].Text=="איסוף עצמי")
    {
      if(this.restaurant.listSetting[i].Show==1)
      {
      if(this.checkBox==0)
      {
      this.checkBox=2;
      localStorage.DELIVERY=2;
      }
      this.isPickUp=1;
      }
    }
    if(this.restaurant.listSetting[i].Text=="ישיבה במקום")
    {
      if(this.restaurant.listSetting[i].Show==1)
      {
      if(this.checkBox==0)
      {
      this.checkBox=3;
      localStorage.DELIVERY=3;
      }
      this.IsSetting=1;
      }
    }
  }
  }
  this.restaurantItem=this.restaurant.MenuList;
  this.startCollectionType();
  this.showProgress=false;
   // });
}
else
{
this.showProgress=false;
this.proxy.showError("ההזמנה שלך ריקה","");
this.orderService.restaurant=null;
this.dataService.price.next("0 - פריטים");
this.changePrice.emit();
this.router.navigate(['../restaurant']);
}
this.dataService.showSearch.next(false);
this.changByFood.emit();
this.dataService.byFood.next(true);
this.changByFood.emit();
   });
 this.dataService.show.next(false);
 this.changeShowPrice.emit();
  }
  
  changeOnFood(position:number,MenuID:number){
    // let pos=0;
    // this.orderService.getOrderAdditon(MenuID,pos,position).subscribe((res:any)=>{
    //   this.orderService.getOrderDetails(false).subscribe((res:any)=>{     
    //   });
    // });
  }
}
